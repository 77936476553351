.basic-layout {
	position: relative;

    height: 100%;
    width: 100%;
	/*
    display: flex;
    flex-direction: row;
    background: linear-gradient(#1c2758,#101630);
    min-height: 0;
    */
}
.admin-side {
	/*position: fixed;*/
	/*top: 0;*/
	/*left: 0;*/
	/*height: 100%;*/
	overflow: auto;
	z-index: 1;
	background: linear-gradient(#1c2758, #101630);
	/*
    position: fixed;
    top: 0;
    left: 0;
    width: 228px;
    flex: none;
    height: 100%;
    min-width: 0;
    background: transparent;
    overflow: auto;
    flex-direction: column;
    */
}
.basic-layout-container {
	position: relative;
	width: 100%;
	height: 100%;
	min-width: 1140px;
	/*
    position: relative;
    display: flex;
    flex-direction: column;
    flex: auto;
    min-width: 976px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px 0 0 10px;
    margin-left: 228px;
    transform-origin: right;
    */
}
.basic-layout-container.sidebar-fold {
	margin-left: 80px;
}
.basic-layout-container.sidebar-unfold {
	margin-left: 200px;
}

/* 主要内容区域 */
.content-layout {
	padding-left: 130px;
	padding-top: 50px;
}

.content-container {
	margin: 10px;
	background-color: white;
}

.content {
	/*    padding: 10px;*/
	padding: 10px 15px;
	background: white;
}
.face-img {
	width: 150px;
	height: 100px;
}
