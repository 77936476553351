/**
 * 自定义Antd的样式
 * 覆盖Antd样式只应该在这里出现，升级Antd时，只需处理此文件的兼容性
 */

.ant-layout-header {
	padding: 0 20px !important;
	line-height: 47px !important;
}

.ant-layout-content {
	margin-top: 0 !important;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	min-width: 1140px;
}

.ant-layout-sider {
}
.ant-menu-dark {
	background: transparent !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
	background: transparent !important;
}
.ant-table-pagination.ant-pagination {
	margin: 16px !important;
}
.ant-pro-table .ant-table-content tr:first-child > th:first-child,
.ant-pro-table .ant-table-row .ant-table-cell:first-child {
	padding-left: 24px;
}
.ant-tabs-nav-list {
	margin: 0 !important;
}
.ant-list-bordered {
	border: 0px !important;
}
.ant-image-img{
	cursor: pointer;
}
.ant-form-item-label {
    max-width: 200px !important
}
